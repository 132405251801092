import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'video-donut-bg': (data) => ({
      background: `url(${data?.leftDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
      '&:after': {
        background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100)`,
      },
  }),
  'capsules-bg-right': (data) => ({
      background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
}));
