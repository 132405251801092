import React, { useState, useRef, useEffect } from 'react';
import { Grid, Paper, Button, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import './../VideoLandingTile/VideoLandingTile.scss';
import Typography from '@material-ui/core/Typography';
import VideoPopUp from './../../VideoPopUp';

export default function VideoLandingTile({ data }) {

  const [open, setOpen] = useState(false);
  const videoId = data?.videoComponent?.videoId;
  const videoType = data?.videoComponent?.videoType;
  const videoTitle = data?.videoComponent?.videoTitle;
  const formatedDateString = (data?.publishingDate !== "undefined NaN" ? data?.publishingDate : "");
  const qumucloudthumbnail = `https://cartus.qumucloud.com/kulu/` + videoId + `/thumbnail`;
  const youtubethumbnail = `https://img.youtube.com/vi/` + videoId + `/mqdefault.jpg?fm=webp&q=100`;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    buttonEl?.current?.querySelectorAll("button").forEach((role) => {
      role.setAttribute('aria-label', 'video thumbnail');
    });
  }
  
  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener("resize", () => { registerAriaLabel() })
    }
  }, []);
  return (
    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} locId="videoComponent" ref={buttonEl}>
      <Paper elevation={0} variant='outlined' className='video-container'>
      <Button className='button-container' onClick={handleClickOpen}>
            <img
              src={
                videoType
                  ? qumucloudthumbnail
                  : youtubethumbnail
              }
              alt='video'
              className='video-img'
              locId="videoType"
            />
            <div className='video-img-overlay' ></div>
            <PlayCircleIcon fontSize='large' className='play-button-icon' />
          </Button>
          <VideoPopUp
            videoId={videoId}
            videoType={videoType}
            open={open}
            setOpen={setOpen}
          />
          <Box sx={{ marginTop: 'auto' }} onClick={handleClickOpen}>
            <Paper elevation={0} className='tiles-details'>
              {videoTitle && (
                <Typography className="video-title" locId='videoTitle'>
                  {videoTitle}
                </Typography>)}
            </Paper>
          </Box>
      </Paper>
    </Grid>
  )
}