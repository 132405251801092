import React from 'react';
import './VideoLanding.scss'
import VideoLandingHeader from './VideoLandingHeader'
import { myStyles } from './styles';
import clsx from 'clsx';

export default function VideoLanding({ landingData, contentData }) {
  const classes = myStyles(landingData);
  return (
    <>
      <div className='video-landing-main'>
        <div className={clsx("video-donut-bg", classes["video-donut-bg"])} locId="leftDonutBackgroundImage" >
          <div className={clsx("capsules-bg-right", classes["capsules-bg-right"])} locId="capsulesBackgroundImage">
            <VideoLandingHeader contentData={contentData} landingData={landingData} />
          </div>
        </div>
      </div>
    </>
  )
}
