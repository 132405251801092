import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
// import PrivacyNotice from '../components/PrivacyNotice';
import VideoLanding from '../components/VideoLanding'
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

export default function videoContentPage({location, pageContext, data }) {

  const [user, setUser] = useState({});
  //const videoContent= data?.allContentfulCustomVideo?.nodes.filter((item)=>pageContext.locale===item.node_locale)
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  const filteredVideo=data?.allContentfulCustomVideo?.nodes.filter((item)=>item?.videoComponent?.seoEntry)
  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulVideoLandingPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.appData?.adminDetails?.s3BucketName}
          basePath={pageContext?.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema ={filteredVideo[0]?.videoComponent}
        />

        <VideoLanding
          landingData={data?.contentfulVideoLandingPage}
          contentData={data?.allContentfulCustomVideo?.nodes}
          
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query videoLandingPageQuery($contentful_id: String, $locale: String) {
    contentfulVideoLandingPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }){
      capsulesBackgroundImage {
        description
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        description
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        description
        file {
          url
        }
      }
      node_locale
      loadMoreButton
      header
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
    }
    allContentfulCustomVideo(filter: {node_locale: {eq: $locale}}) {
      nodes {
        contentful_id
        entryTitle
        node_locale
        videoComponent {
          contentful_id
          createdAt
          entryTitle
          videoId
          videoType
          videoTitle
          dateOfPublishing(formatString: "YYYY-MM-DD")
          seoEntry {
            metaDescription {
              metaDescription
            }
          }
        }
      }
    }
  }
`;
