import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import './VideoLandingHeader.scss';
import Container from '@material-ui/core/Container';
import VideoLandingTile from './../VideoLandingTile'
import LoadMoreButton from './../../LoadMoreButton';

export default function VideoLandingHeader({ contentData, landingData }) {
  const numberOfTilePerPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_PER_PAGE);
  const videoList = contentData?.map(video => {
    const entryTitle = video?.entryTitle;
    const videoId = video?.videoComponent?.videoId;
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    const dateinTimestamp = new Date(video?.videoComponent?.dateOfPublishing).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const formatedDateString = monthString + ' ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...video,
      entryTitle,
      videoId,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp
    };
  });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }
  videoList.sort(comparePublishingDate);

  const [colsCount, setColsCount] = useState(numberOfTilePerPage);

  const tilesAddMore = () => {
    let nextColsCount = contentData?.length > colsCount + numberOfTilePerPage
      ? colsCount + 12
      : contentData?.length;
    setColsCount(nextColsCount);
  };

  return (
    <Container maxWidth="xl" className='videolanding-cls'>
      <Grid container className='videolanding-div'>
      <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
        <Grid container xl={10} lg={10} md={10} sm={10} xs={10}>
          <div className='tiles-container'>
            <h1 locId="header" >{landingData?.header}</h1>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="container-padding">
              {videoList.slice(0,colsCount).map((video) => (
                <VideoLandingTile data={video} />
              ))}
            </Grid>
          </div>
        </Grid>
        <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
        {colsCount < contentData?.length ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LoadMoreButton loadMoreButton={landingData?.loadMoreButton} handleLoadMore={tilesAddMore} tabindex="0" />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  )
}