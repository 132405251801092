import React from 'react';
import { Grid, Dialog, DialogContent, IconButton, Box, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './VideoPopUp.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

export default function VideoPopUp({ videoId, videoType, open, setOpen }) {

  const qumucloudpopUp = `https://cartus.qumucloud.com/view/` + videoId;
  const youtubepopup = `https://www.youtube.com/embed/` + videoId;
  return (
    <Dialog open={open} TransitionComponent={Transition} onClick={() => setOpen(false)} maxWidth='md'>
      <Box className='popup-overlay'>
        <IconButton className='popup-close-icon' onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
        <DialogContent className='cust-dialog'>
          <Grid container className='dialog-content'>
            <div className='iframe-class'>
              <iframe
                width="560"
                height="349"
                className="responsive-iframe"
                src={videoType ? qumucloudpopUp : youtubepopup} />
            </div>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
